import { createApp } from 'vue';
import { createPinia } from 'pinia';
import 'vuetify/styles';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as Sentry from '@sentry/vue';
import { axios } from './store';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const app = createApp(App);
const pinia = createPinia();

Sentry.init({
  app,
  dsn: 'https://5d33c372072f43ceb762f9e8f5b52ebc@o4505441895972864.ingest.sentry.io/4505441947156480',
  denyUrls: ['localhost'],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_APP_API_ENDPOINT],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

(async () => {
  await axios.get(import.meta.env.VITE_APP_API_ENDPOINT + `/sanctum/csrf-cookie`);

  app.use(router);
  app.use(vuetify);
  app.use(pinia);
  app.mount('#app');
})();
