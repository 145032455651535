import Axios from 'axios';

export const axios = Axios.create({
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  baseURL: import.meta.env.VITE_APP_API_ENDPOINT,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    // 'Cache-Control': 'no-cache',
  },
  withCredentials: true,
});
