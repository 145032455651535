import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
  routes: [
    {
      path: '/:hash',
      name: 'home',
      component: () => import('@/components/Pages/Section.vue'),
    },
    {
      path: '/:hash/:section_hash',
      name: 'schedule',
      component: () => import('@/components/Pages/Schedule.vue'),
      meta: { title: '予約日の選択' },
    },
    {
      path: '/:hash/:section_hash/inquiry',
      name: 'inquiry',
      component: () => import('@/components/Pages/Inquiry.vue'),
      meta: { title: '予約確認' },
    },
    {
      path: '/:hash/:section_hash/reservation',
      name: 'reservation',
      component: () => import('@/components/Pages/Reservation/Reservation.vue'),
      meta: { title: '予約内容の入力' },
    },
  ],
});

export default router;
